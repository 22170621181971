import React from 'react';
import './ServiceModal.css';

const ServiceModal = ({ show, service, onClose }) => {
  if (!show) {
    return null; // Don't render the modal if it's not supposed to show
  }

  return (
    <div className="modal-backdrop">
      <div className="modal">
        <button className="close-btn" onClick={onClose}>×</button>
        <div className="modal-header">
          <h4>{service.title}&nbsp;<i className={service.icon}></i></h4>
        </div>
        <div className="modal-body">
          <p>{service.description}</p>
        </div>
      </div>
    </div>
  );
}

export default ServiceModal;
