import React, { useState } from 'react';
import ServiceModal from './ServiceModal/ServiceModal';
import './Services.css';

const Services = () => {
  const [openItem, setOpenItem] = useState(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 720);

  const toggleItem = index => {
    setOpenItem(index);
  };

  const handleClose = () => {
    setOpenItem(null);
  };

  const handleResize = () => {
    setIsMobile(window.innerWidth < 720);
  };

  // Set up an event listener for resize
  React.useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const services = [
    {
      icon: 'fas fa-laptop-code',
      title: 'Web Development',
      description: 'Create a modern, responsive, and user-friendly website tailored to meet your needs, using the latest technologies to boost your online presence and business success.'
    },
    {
      icon: 'fas fa-globe',
      title: 'Web Deployment',
      description: 'Have a website made already and need it deployed? Look no further!'
    },
    {
      icon: 'fas fa-database',
      title: 'Database Management',
      description: 'Need to clean up your database? Or better yet, do you need a database? Look no further!'
    },
    {
      icon: 'fas fa-laptop-code',
      title: 'Website Updates',
      description: 'Don’t let your website fall behind the times! Let me help you update your website to attract and retain more visitors, boost engagement, and increase sales. Contact me here.'
    }
  ];

  return (
    <section className="service section" id="services">
      <div className="container">
        <div className="row">
          <div className="section-title padd-15">
            <h2>Services</h2>
          </div>
        </div>
        <div className="row">
          {services.map((service, index) => (
            <div className="service-item padd-15" onClick={() => toggleItem(index)} key={index}>
              <div className="service-item-inner">
                <div className="icon">
                  <i className={service.icon}></i>
                </div>
                <h4>{service.title}</h4>
                <p className={isMobile ? 'truncate' : ''}>
                  {isMobile ? service.description.substring(0, 35) + '...' : service.description}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
      {openItem !== null && (
        <ServiceModal 
          show={openItem !== null} 
          service={services[openItem]} 
          onClose={handleClose}
        />
      )}
    </section>
  );
};

export default Services;
